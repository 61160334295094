.heading1 {    
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-align: center;


    @media (max-width: 576px) {
        font-weight: 700;

    }
    @media (max-width: 350px) {
        font-size: 35px;
    }
}

