@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

$textColor: #ffffff;
$textColorGreen: #499662;
$textColorOrange: #ffa26b;
$textColor: #ffffff;
$bgColor: #499662;
$activeBgColor: #499660e3;
$trackColor: #228b22;
$handleColor: #008000;
$handleHoverColor: #006400;

* {
  margin: 0;
  padding: 0;
}

.p,
p,
h1,
h2,
h4,
h5,
h6,
a {
  font-family: "Roboto";
}

p {
  margin-bottom: 0px !important;
}

a,
Link {
  text-decoration: none !important;
}

.textGreen,
input {
  color: #499662;
}

.fontColor {
  color: #499662 !important;
}

.customCurrecnyDropDown {
  border-color: #499662 !important;
  border: 1.5px solid #499662 !important;
  border-radius: 17px !important;

}

.textOrange {
  color: #ffa26b !important;
}

.textWhite {
  color: #ffff;
}

.textGray {
  color: #434141;
}

.d-none {
  display: none;
}

#placeholder::placeholder {
  color: #499662 !important;
  background-color: #ffffff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.customDropDown {
  box-shadow: none !important;
}

.modal {
  .modal-content {
    border: 1px solid white;
    background-color: #676868;
    border-radius: 30px;
    box-shadow: 6.729650974273682px 6.729650974273682px 5.383720874786377px 0px rgba(255, 255, 255, 0.08) inset;

    box-shadow: -6.729650974273682px -6.729650974273682px 5.383720874786377px 0px rgba(255, 255, 255, 0.04) inset;
  }

  .modal-footer {
    margin-top: 15px;

    .btn {
      margin: 0px !important;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      padding: 20px;
      font-weight: 600;
      font-size: 18px;
      background: #26643a !important;
      background-size: 150% auto;
    }

    .btn-right {
      border: solid rgba(255, 255, 255, 0.6) 1px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      padding: 15px;
      font-size: 18px;
      // background: #499662;

      background-size: 150% auto;
    }

    .btn-left {
      border: solid rgba(255, 255, 255, 0.6) 1px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 30px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      padding: 15px;
      font-size: 18px;
      // background: #499662;

      background-size: 150% auto;
    }
  }
}

.landingBtn {
  background-color: transparent !important;

  &:hover {
    background-color: $textColorOrange !important;
  }
}

.canvaCursorPointer {
  cursor: pointer !important;
}

video {
  width: 90% !important;
  margin-left: 10px;
  padding-top: 15px !important;
}

// custom-styles.scss or your component's style section
.phoneInput {}

.inputField {
  font-size: 17.3px;
  font-weight: 400;
  border: 2.30723px solid rgba(99, 173, 120, 0.7);
  border-radius: 17px;
  display: block;
  width: 100%;
  height: 3.55rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  background-color: #fff;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out;

  &::placeholder {
    color: #499662 !important;
  }

  &:focus {
    // font-weight: 600 !important;
    border: 2.5px solid #499662;
    outline: none;
    transition: width 35s ease-in-out;
    color: #499662 !important;
    box-shadow: none;

    &:active {
      background: white !important;
    }
  }
}

.errorMessages {
  font-family: "Roboto" !important;
  color: red;
  text-align: start !important;
  font-size: 14px;
}

.react-tel-input,
.react-tel-input .form-control {
  border: none !important;
  outline-style: none !important;

  &:focus {
    border: none;
    box-shadow: none !important;
    outline: none !important;
  }
}

.form-control {
  border: none !important;
}

.css-t3ipsp-control {
  box-shadow: none !important;
  border: none !important;

  &:focus-visible,
  &:focus,
  &:hover {
    box-shadow: none !important;
    border-color: none !important;
    box-shadow: none !important;
    border: none !important;
  }

}

.textarea {
  font-size: 17.3px;
  font-weight: 400;
  border: 2.30723px solid rgba(99, 173, 120, 0.7);
  border-radius: 17.3px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  background-color: #fff;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out;

  &::placeholder {
    color: #499662;
  }

  &:focus {
    font-weight: 600 !important;
    border: 2.5px solid #499662;
    outline: none;
    transition: width 35s ease-in-out;
    color: #499662 !important;
    box-shadow: none;

    &:active {
      background: white !important;
    }
  }
}

#appleid-signin {
  font-family: "Roboto" !important;

  svg {
    rect {
      height: 38% !important;
    }
  }
}

.css-hlgwow {
  height: 3.55rem !important;
  text-align: left !important;
}

.input-group {

  .absolute-dollar-sign {
    top: 9px !important;
    left: 10px !important;

  }

}

@media only screen and (max-width: 767px) {
  video {
    width: 96% !important;
    margin-left: 10px;
    padding-top: 26px !important;
  }

  /* Add other mobile-specific styles here */
}
.gpay-card-info-container {
  width: 100% !important; /* Ensure width is 100% */
  height: 45px; /* Set the desired height */
}