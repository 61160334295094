@import "../../App.scss";

.mainButton {
  line-height: initial;
  min-height: 3.55rem;
  width: 100%;
  border-radius: 17px !important;
  border: none !important;
  cursor: pointer !important;
  font-family: "Roboto" !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  color: $textColor;
  background: linear-gradient(
    to right,
    #53c075f3,
    rgba(10, 45, 30, 0.8),
    #499662
  );

  background-size: 150% auto;
  &:hover {
    // background-position: right center; /* change the direction of the change here */
    color: white;
  }
  &:active {
    background-color: $activeBgColor;
  }

  @media (max-width: 1163px) {
    cursor: pointer !important;
    // font-size: 18px !important;
    font-weight: 500 !important;
    text-align: center;
  }
  @media (max-width: 1033px) {
    // font-size: 16px !important;
  }

  @media (max-width: 480px) {
    font-size: 20px !important;
  }
}
.mobileButton{
  line-height: initial;
  min-height: 3rem;
  width: 100%;
  border-radius: 5px !important;
  border: none !important;
  cursor: pointer !important;
  font-family: "Roboto" !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  color: $textColor;
  background: linear-gradient(
    to right,
    #53c075f3,
    rgba(10, 45, 30, 0.8),
    #499662
  );

  background-size: 150% auto;
  &:hover {
    // background-position: right center; /* change the direction of the change here */
    color: white;
  }
  &:active {
    background-color: $activeBgColor;
  }
}
.bg-secondary {
  --bs-bg-opacity: 1;
  background: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}
