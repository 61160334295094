.modal-button {
  .btn-primary {
    color: white !important;
    background-color: transparent !important;
    cursor: pointer !important ;
    box-shadow: none !important;
    background: linear-gradient(
      to right,
      #53c075f3,
      rgba(10, 45, 30, 0.8),
      #499662
    );

    background-size: 150% auto;
    &:hover {
      background-color: transparent !important;
      color: white !important;
      box-shadow: none !important;
    }
  }
}

.react-currency-input::placeholder {
  color: rgb(255, 162, 107) !important;
}

/* For older versions of Firefox */
.react-currency-input::-moz-placeholder {
  color: rgb(255, 162, 107) !important; /* Change this to the desired color */
}

/* For Internet Explorer 10/11 */
.react-currency-input:-ms-input-placeholder {
  color: rgb(255, 162, 107) !important; /* Change this to the desired color */
}

/* For Microsoft Edge */
.react-currency-input::placeholder {
  color: rgb(255, 162, 107) !important; /* Change this to the desired color */
}
.no-rating {
  border: none;
  margin: 0;
  width: 20px;
  height: 20px;
}