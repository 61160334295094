$primary-color: #ffff;
$background-color: #ffff;
$hover-background-color: #499662;
$box-shadow-color: rgba(0, 0, 0, 0.5);

.text-primary {
  color: $primary-color !important;
}

.btn-primary {
  border-color: $primary-color !important;
  // background-color: $primary-color !important;
  background-color: $primary-color !important;
 // border: 1px solid #499662 !important;
  color: #499662 !important;
  &:hover {
    color: #fff !important;

    background-color: $hover-background-color !important;
  }
  &:focus {
    box-shadow: 0px 0px 0px $hover-background-color !important;
  }
}

.shadow-md {
  box-shadow: 0px 5px 10px $box-shadow-color;
}

.sidebar {
  position: sticky;
  top: 0;
  height: calc(100vh - 10px); /* Subtract the margin-right value */
  overflow-y: auto;
  margin-right: 10px;
  background: #fff;
  width: 250px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  height: 100vh;
  transition: 0.8s;
  position: -webkit-sticky; /* Safari */
  z-index: 99;
  .sd-body {
    ul {
      li {
        .sd-link {
          display: block;
        }
      }
    }
  }
}
.sidebar.active {
  transform: translateX(0%);
  width: 80px;
  right: 0;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;

  .sd-body {
    ul {
      li {
        .sd-link {
          display: none;
        }
      }
    }
  }
}
.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px;
}

.sd-body {
  padding: 15px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;

  ul {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;

    li {
      list-style: none;
      margin-bottom: 8px;
      border: '1px solid red' !important;
      i {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 40px;
        height: 40px;
      }
      .fontColorNav {
        color: rgba(73, 150, 98, 0.6);
      }
      .sd-link {
        display: inline-block;
        width: 100%;
        padding: 10px 16px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          color: rgb(73, 150, 98);
        }
      }
      .activeNav {
        color: #499662;
        font-weight: bold;
      }
    }
  }
}

.Logout_button_style {
  position: absolute;
  top: 90%;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  border: none !important;
  color: #499662 !important;
  box-shadow: none !important;
  // border: 1px solid red!important;
  &:hover {
    background: #fff !important;
  }
}
// @media (max-width: 767px) {
//   .sidebar {
//     left: -250px; /* Sidebar off-screen on mobile */
//     display: none; /* Hide the sidebar */
//   }

//   .sidebar.active {
//     left: 0; /* Slide into view on mobile */
//   }
// }
