.modal{
   
  box-shadow: 6.293604373931885px 6.293604373931885px 5.034883499145508px 0px rgba(255, 255, 255, 0.08) inset;

box-shadow: -6.293604373931885px -6.293604373931885px 5.034883499145508px 0px rgba(255, 255, 255, 0.04) inset;

 ul, ol {
  list-style: none;
}
/* SelectCountry.scss */

ul.language-list {
  list-style-type: none;
}

ul.language-list li {
  transition: transform .5s; /* Animation */;
  // opacity: 0.6;
  color:rgba(255, 255, 255, 0.891);
  cursor: pointer !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.selected-list{
  transform: scale(1.18); /* Zoom in to 110% on hover */
  font-weight: 600;
  opacity: 100% !important;
  cursor: pointer !important;
  color: white  !important;
}

ul.language-list .flag-image {
  width: 40px;
  height: 30px;
  
  }
}