.modal-content {
  border: 1px solid white;
  background-color: #676868 !important;
  border-radius: 30px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.30) 100%);
box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset !important;
backdrop-filter: blur(10px) !important;
}
 .modalBody {
  min-height: 140px;
  .border-success {
    height: 100px;
    width: 93%;
  }
}
.FooterModal {
  border: none;
  .FooterModalCon {
    margin: 0px !important;
  }
}
