
.loader {
  background-color: transparent !important;
  height: 100%;
  width: 100%;
  .loaderinner {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
}
