.input-group-text {
  border-right: none !important;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border:1px  solid #499662
}

.absolute-dollar-sign {
  position: absolute;
    background: transparent;
    border: none;
    top: 10px;
    left: 5px;
    font-size: 25px;
    font-weight: bold;
    color: #499662;
}

.orangeBtn {
  background: rgb(253, 160, 116) !important;
}

*{
  font-family: 'Roboto';
}