.backButton {
    background-color: white !important;
    border: none;

    &focus {
        background-color: white !important;
    }
    &hover {
        background-color: white !important;
    }
}
