.container {
  .drawer {
    position: fixed;
    top: 0;
    left: -300px; /* Initial position outside the viewport */
    width: 250px;
    height: 100%;
    background-color: #fff;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease-in-out;
    z-index: 1000;
  }

  .open {
    left: 0; /* Opened position */
    .drawer-links {
      li {
        
      }
    }
    .toggle-button {
      // border: 2px solid red;
      margin: 20px 0px 0px 25px;
      position: static;
    }
  }
}

.drawer-links {
  list-style: none;
  padding: 20px;
}

.drawer-links li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.2s;
}

.drawer-links li:hover {
  background-color: #ccc;
}

.toggle-button {
  position: absolute;
  // margin: 10px 10px 0px -5px;
  padding: 5px 8px 5px 8px;
  background-color: white;
  color: #006400;
  border: 1px solid #006400;
  border-radius: 5px;
  cursor: pointer;
  // : center;
  // : center;
}
