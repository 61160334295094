.userinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 230px;

  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 24px;
    color: #499662;
    text-align: center;
   width: 230px;
  //  border: 1px solid red;
  }
  img {
    width: 82px;
    height: 82px;
    border: 2px solid rgba(99, 173, 120, 0.7);
    border-radius: 50%;
    margin: 30px 0px 20px 0px;
  }
  h5 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #499662;
    text-align: center;
    width: 130px;
    //  border: 1px solid red;
  
  }
  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #499662;
  }
  .no-rating {
    border: none;
    margin: 0;
    width: 20px;
    height: 20px;
  }
}
